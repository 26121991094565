// Generated by Framer (e1a43d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["Mhrrc_WRK", "bZfgLnpCQ"];

const serializationHash = "framer-6fMUF"

const variantClassNames = {bZfgLnpCQ: "framer-v-6xk33q", Mhrrc_WRK: "framer-v-vab90z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Mhrrc_WRK", "Variant 2": "bZfgLnpCQ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Mhrrc_WRK"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Mhrrc_WRK", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 402, intrinsicWidth: 655.5, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 804, pixelWidth: 1311, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/M1oS5OAzmL9apAoZ1jmSipU5PP4.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/M1oS5OAzmL9apAoZ1jmSipU5PP4.png?scale-down-to=512 512w,https://framerusercontent.com/images/M1oS5OAzmL9apAoZ1jmSipU5PP4.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/M1oS5OAzmL9apAoZ1jmSipU5PP4.png 1311w"}} className={cx(scopingClassNames, "framer-vab90z", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Mhrrc_WRK"} ref={refBinding} style={{backgroundColor: "rgba(0, 0, 0, 0)", ...style}} variants={{bZfgLnpCQ: {backgroundColor: "rgb(184, 39, 39)"}}} {...addPropertyOverrides({bZfgLnpCQ: {"data-framer-name": "Variant 2", background: undefined}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6fMUF.framer-1ma3e3o, .framer-6fMUF .framer-1ma3e3o { display: block; }", ".framer-6fMUF.framer-vab90z { height: 322px; overflow: visible; position: relative; width: 395px; }", ".framer-6fMUF.framer-v-6xk33q.framer-vab90z { aspect-ratio: 1.2251552795031055 / 1; height: var(--framer-aspect-ratio-supported, 322px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 322
 * @framerIntrinsicWidth 394.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bZfgLnpCQ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertUw_ssNgN: React.ComponentType<Props> = withCSS(Component, css, "framer-6fMUF") as typeof Component;
export default FramertUw_ssNgN;

FramertUw_ssNgN.displayName = "Services-placeholder";

FramertUw_ssNgN.defaultProps = {height: 322, width: 394.5};

addPropertyControls(FramertUw_ssNgN, {variant: {options: ["Mhrrc_WRK", "bZfgLnpCQ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramertUw_ssNgN, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})